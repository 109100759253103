import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client';
import { createFragmentRegistry } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { ReactNode, useMemo } from 'react';

import { MlbGatewayProxy } from '../config/types';
import {
  BTC_CONTENT_FRAGMENT,
  BTC_PIPELINE_FRAGMENT,
  MULTICAST_MAPPING_FRAGMENT,
} from '../operator-gateway/btc/fragments';
import { FEED_POLICY_FRAGMENT, VIEWING_POLICY_FRAGMENT } from '../operator-gateway/esni/fragments';
import { AUDIT_VIEW_FRAGMENT } from '../operator-gateway/fragments';
import { PIPELINE_CONFIG_FRAGMENT } from '../operator-gateway/pipeline-config/fragments';

export interface ApolloProps {
  children: ReactNode;
  mlbOperatorGatewayProxyConfig: MlbGatewayProxy;
}

export default function Apollo({ children, mlbOperatorGatewayProxyConfig }: ApolloProps) {
  const { baseUrl, endpoint, endpoints, wssBase } = mlbOperatorGatewayProxyConfig;
  const uri = `${baseUrl}${endpoints[endpoint]}`;

  const cache = useMemo(
    () =>
      new InMemoryCache({
        fragments: createFragmentRegistry(
          AUDIT_VIEW_FRAGMENT,
          BTC_CONTENT_FRAGMENT,
          BTC_PIPELINE_FRAGMENT,
          FEED_POLICY_FRAGMENT,
          PIPELINE_CONFIG_FRAGMENT,
          VIEWING_POLICY_FRAGMENT,
          MULTICAST_MAPPING_FRAGMENT,
        ),
      }),
    [],
  );

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        const oktaStorage = localStorage.getItem('okta-token-storage');
        const token = oktaStorage ? JSON.parse(oktaStorage)?.accessToken.accessToken : '';
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      }),
    [],
  );

  const httpLink = useMemo(() => new HttpLink({ uri }), [uri]);

  const wsLink = useMemo(
    () =>
      new GraphQLWsLink(
        createClient({
          url: `${wssBase}${endpoints[endpoint]}`,
        }),
      ),
    [endpoint, endpoints, wssBase],
  );

  const link = useMemo(
    () =>
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink,
      ),
    [httpLink, wsLink],
  );

  const client = useMemo(
    () =>
      new ApolloClient({
        cache,
        connectToDevTools: true,
        defaultOptions: {
          mutate: { errorPolicy: 'all' },
          query: { errorPolicy: 'all' },
          watchQuery: {
            errorPolicy: 'all',
            /*
             * Prevents polling if the user's browser tab is not visible.
             * https://github.com/apollographql/apollo-client/pull/11397
             */
            skipPollAttempt: () => document.hidden,
          },
        },
        link: authLink.concat(link),
      }),
    [authLink, cache, link],
  );

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
